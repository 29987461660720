// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/useHighlight.ts"
);
import.meta.hot.lastModified = "1688723976413.6633";
}
// REMIX HMR END

import { H } from "highlight.run";
import { useUserChanged } from "./useUser";

export function useHighlight() {
  useUserChanged((user) => {
    if (!user) {
      return;
    }

    H.identify(user.id, {
      email: user.email,
    });
  });
}
