// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HighlightInit.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HighlightInit.tsx");
  import.meta.hot.lastModified = "1688723976413.1096";
}
// REMIX HMR END

import { H } from "highlight.run";
import { useEffect } from "react";
export function HighlightInit({
  projectId,
  ...highlightOptions
}) {
  _s();
  useEffect(() => {
    projectId && H.init(projectId, highlightOptions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
_s(HighlightInit, "OD7bBpZva5O2jO+Puf00hKivP7c=");
_c = HighlightInit;
var _c;
$RefreshReg$(_c, "HighlightInit");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;